$(function () {

    "use strict";

    //===== Prealoder
    $(window).load(function() {
        $("#loading").fadeOut(500);
    });

    

    //===== Sticky

    $(window).on('scroll', function (event) {
        var scroll = $(window).scrollTop();
        if (scroll < 110) {
            $("header").removeClass("sticky");
        } else {
            $("header").addClass("sticky");
        }
    });

    // slicknav
    $("#mobile-menu").slicknav({
        prependTo: "#mobile-menu-wrapp",
        'allowParentLinks': true
    });

    // back to top
    var btn = $('#button');

    $(window).scroll(function() {
      if ($(window).scrollTop() > 600) {
        btn.addClass('show');
      } else {
        btn.removeClass('show');
      }
    });
    
    btn.on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({scrollTop:0}, 1000);
    });
    
    //===== seller Active slick slider
    $('.slider-carousel-active, .hero-slider-active').slick({
        dots: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: false,
        prevArrow: '<span class="prev"><i class="fal fa-long-arrow-alt-left"></i></span>',
        nextArrow: '<span class="next"><i class="fal fa-long-arrow-alt-right"></i></span>',
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1201,
                settings: {
                    slidesToShow: 1,
                }
        },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                }
        },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
        },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
        }
        ]
    });

        //===== seller Active slick slider
        $('.ablut-us-img-carousel').slick({
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows: false,
            prevArrow: '<span class="prev"><i class="fal fa-long-arrow-alt-left"></i></span>',
            nextArrow: '<span class="next"><i class="fal fa-long-arrow-alt-right"></i></span>',
            speed: 1500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1201,
                    settings: {
                        slidesToShow: 3,
                    }
            },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                    }
            },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
            },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
            }
            ]
        });

        //===== seller Active slick slider
        $('.testimonial-carousel-active').slick({
            dots: true,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 3000,
            arrows: false,
            prevArrow: '<span class="prev"><i class="fal fa-long-arrow-alt-left"></i></span>',
            nextArrow: '<span class="next"><i class="fal fa-long-arrow-alt-right"></i></span>',
            speed: 1500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1201,
                    settings: {
                        slidesToShow: 1,
                    }
            },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                    }
            },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
            },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
            }
            ]
        });


    //===== counter up
    $('.count').counterUp({
        delay: 10,
        time: 2000
    });

    $(".single-price-content").hover(function() {
        $('.single-price-content').removeClass('active');
        $(this).addClass('active');
    });

});
